<template>
  <div class="commodity">
    <el-card>
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="任务名称：">
          <el-input
            v-model="searchForm.search_input"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="searchForm.status" placeholder="请选择">
            <el-option
              v-for="item in status_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-dropdown style="margin-bottom: 20px">
        <el-button type="primary">
          添加任务<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="openDialog('tbDialogVisible')"
            >淘宝物料ID导入</el-dropdown-item
          >
          <el-dropdown-item @click.native="openDialog('excelDialogVisible')"
            >Excel导入</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
      >
        <el-table-column fixed prop="id" label="ID" width="70">
        </el-table-column>
        <el-table-column prop="task_name" label="任务名称"> </el-table-column>
        <el-table-column prop="task_mark" label="物料"> </el-table-column>
        <el-table-column prop="item_num" label="已导入商品"> </el-table-column>
        <el-table-column prop="update_frequency" label="更新频率">
        </el-table-column>
        <el-table-column prop="topic_name" label="关联专题"> </el-table-column>
        <el-table-column prop="last_time" label="上次更新"> </el-table-column>
        <el-table-column prop="status" label="状态" width="70">
          <template slot-scope="scope">
            <span v-if="scope.row.type == 2">--</span>
            <span
              v-else
              :style="
                'color:' + (scope.row.status == 1 ? '#67C23A' : '#F56C6C') + ';'
              "
              >{{ getStatusToDetail(scope.row.status) }}</span
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="openDetail(scope.row)" type="text" size="small"
              >详情</el-button
            >
            <el-popover
              v-if="scope.row.type != 2 && scope.row.status == 2"
              style="margin: 0 10px"
              placement="top"
              width="160"
              title="确定启用？"
              v-model="scope.row.visible"
            >
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="scope.row.visible = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="start_use(scope)"
                  >确定</el-button
                >
              </div>
              <el-button
                @click="show_table_row_pop(scope)"
                slot="reference"
                type="text"
                size="small"
                >启用</el-button
              >
            </el-popover>
            <el-button
              v-if="scope.row.type != 2 && scope.row.status == 1"
              @click="stop_table_row(scope)"
              slot="reference"
              type="text"
              size="small"
              >停用</el-button
            >

            <el-button
              @click="editGoodsImportTaskDetail(scope)"
              slot="reference"
              type="text"
              size="small"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 15px; text-align: center"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-sizes="[20, 30, 40, 50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      title="excel导入"
      :visible.sync="excelDialogVisible"
      width="40%"
      @close="clearDialogOldData('excelRuleForm')"
    >
      <el-alert
        v-show="excelRuleForm.errMessage && excelRuleForm.errMessage.length > 0"
        :title="excelRuleForm.errMessage"
        :closable="false"
        type="warning"
        style="margin-bottom: 10px"
      >
      </el-alert>
      <el-link type="primary" class="go_down_model" :href="downModelHref"
        >下载导入模板</el-link
      >
      <el-form
        :inline="true"
        label-position="right"
        label-width="120px"
        :model="excelRuleForm"
        :rules="excelRules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="任务名称：" prop="task_name">
              <el-input
                :disabled="!!excelRuleForm.id"
                v-model="excelRuleForm.task_name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上传文件：" prop="file">
              <ElmUpLoad
                ref="elmUpLoad"
                :disabled="!!excelRuleForm.id"
                @upload_success="upload_success"
                @upload_err="upload_err"
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :fileList="excelRuleForm.file"
              ></ElmUpLoad>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所属专题：" prop="zhuanTi">
              <el-select
                style="width: 400px"
                v-model="excelRuleForm.zhuanTi"
                multiple
                filterable
                reserve-keyword
                placeholder="请输入关键词"
              >
                <el-option
                  v-for="item in excelRuleForm.zt_value &&
                  excelRuleForm.zt_value.length > 0
                    ? excelRuleForm.options
                    : topicList"
                  :label="item.topic_name"
                  :key="item.topic_id"
                  :value="item.topic_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog('excelDialogVisible')">取消</el-button>
        <el-button type="primary" @click="onSubmit('excelRuleForm')">{{
          !excelRuleForm.id ? "开始导入" : "保存"
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="淘宝物料ID导入"
      :visible.sync="tbDialogVisible"
      width="60%"
      @close="clearDialogOldData('tbRuleForm')"
    >
      <el-alert
        v-show="tbRuleForm.errMessage && tbRuleForm.errMessage.length > 0"
        :title="tbRuleForm.errMessage"
        :closable="false"
        type="warning"
        style="margin-bottom: 10px"
      >
      </el-alert>
      <el-form
        :inline="true"
        label-position="right"
        label-width="120px"
        :model="tbRuleForm"
        :rules="tbRules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="任务名称：" prop="task_name">
              <el-input
                :disabled="!!tbRuleForm.id"
                v-model="tbRuleForm.task_name"
                placeholder="请输入"
                style="width: 400px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="物料id：" prop="task_mark">
              <el-input
                :disabled="!!tbRuleForm.id"
                v-model="tbRuleForm.task_mark"
                placeholder="请输入"
                style="width: 400px"
              ></el-input>
              <div class="input_down_tishi">多个物料ID用","隔开</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="更新频率：" prop="update_frequency">
              <el-input
                v-model.number="tbRuleForm.update_frequency"
                type="number"
                placeholder="请输入"
                style="width: 100px"
              ></el-input>
              <div class="tb_pinlv_tishi">分钟/次</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所属专题：" prop="zhuanTi">
              <el-select
                style="width: 400px"
                v-model="tbRuleForm.zhuanTi"
                multiple
                filterable
                reserve-keyword
                placeholder="请输入关键词"
              >
                <el-option
                  v-for="item in tbRuleForm.zt_value &&
                  tbRuleForm.zt_value.length > 0
                    ? tbRuleForm.options
                    : topicList"
                  :label="item.topic_name"
                  :key="item.topic_id"
                  :value="item.topic_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog('tbDialogVisible')">取消</el-button>
        <el-button type="primary" @click="onSubmit('tbRuleForm')">{{
          !tbRuleForm.id ? "开始导入" : "保存"
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="停用导入任务"
      :visible.sync="stopDialogVisible"
      width="40%"
      @close="clearDialogOldData('stopRuleForm')"
    >
      <el-alert
        v-show="stopRuleForm.errMessage && stopRuleForm.errMessage.length > 0"
        :title="stopRuleForm.errMessage"
        :closable="false"
        type="warning"
        style="margin-bottom: 10px"
      >
      </el-alert>
      <el-form
        label-position="right"
        label-width="120px"
        :model="stopRuleForm"
        :rules="stopRules"
      >
        <el-form-item label="任务名称：">
          <el-input
            v-model="stopRuleForm.name"
            :disabled="true"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item label="删除已导入商品" prop="is_delete">
          <el-select v-model="stopRuleForm.is_delete" placeholder="请选择">
            <el-option
              v-for="item in del_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="input_down_tishi">仅从专题中删除商品</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog('stopDialogVisible')">取消</el-button>
        <el-button type="primary" @click="onSubmit('stopRuleForm')"
          >确定停用</el-button
        >
      </span>
    </el-dialog>
    <joinTopic
      :params="showSaveTopicDialogParams"
      :defData="defData"
      v-if="showSaveTopicDialog"
      @close="
        get_zhuanti_list();
        showSaveTopicDialog = false;
      "
    ></joinTopic>
  </div>
</template>
<script>
import * as api from "@/config/api";
import ElmUpLoad from "@/components/elmUpLoad/index.vue";
import joinTopic from "../../goodlib/JoinTopic/index.vue";
export default {
  components: {
    ElmUpLoad,
    joinTopic,
  },
  data() {
    return {
      defData: {},
      showSaveTopicDialogParams: {},
      showSaveTopicDialog: false,
      // 下载模板地址
      downModelHref:
        "https://imgcdn.xiaomanxiong.net/new_img_admin/20210816/a83088b26a72489710a5c93a5fefca59.xlsx",
      // excel弹出form
      excelRuleForm: {
        id: null,
        task_name: "",
        zhuanTi: [],
        task_mark: "",
        options: [],
        loading: false,
        // 错误提示消息
        errMessage: "",
        // 专题输入框的值
        zt_value: "",
        okSubmit: true,
      },
      // excel弹出规则
      excelRules: {
        task_name: [
          {
            required: true,
            message: "请输入任务名称",
            trigger: "blur",
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        file: [
          {
            required: true,
            message: "请上传文件",
            trigger: "",
          },
        ],
        // zhuanTi: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      // 淘宝弹出form
      tbRuleForm: {
        id: null,
        task_name: "",
        zhuanTi: [],
        task_mark: "",
        update_frequency: undefined,
        options: [],
        loading: false,
        // 错误提示消息
        errMessage: "",
        // 专题输入框的值
        zt_value: "",
        okSubmit: true,
      },
      // 淘宝弹出规则
      tbRules: {
        task_name: [
          {
            required: true,
            message: "请输入任务名称",
            trigger: "blur",
          },
        ],
        task_mark: [
          {
            required: true,
            message: "请输入物料id",
            trigger: "blur",
          },
        ],
        update_frequency: [
          {
            validator: (rule, value, callback) => {
              if (value === undefined || value == "") {
                return callback();
              }
              if (!Number.isInteger(value)) {
                callback(new Error("请输入数字值"));
              } else {
                if (value < 0) {
                  callback(new Error("必须大于0"));
                } else {
                  callback();
                }
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        // zhuanTi: [{ required: true, message: "请选择", trigger: "blur" }],
      },
      searchForm: {
        // 顶部搜索值
        search_input: "",
        // 状态选择
        status: undefined,
      },
      stopRuleForm: {
        id: "",
        name: "",
        is_delete: undefined,
        okSubmit: true,
        errMessage: "",
      },
      stopRules: {
        is_delete: [
          {
            required: true,
            message: "请选择",
            trigger: "blur",
          },
        ],
      },
      // 状态列表
      status_list: [
        {
          value: 1,
          label: "启用",
        },
        {
          value: 2,
          label: "停用",
        },
      ],
      // 删除选项
      del_options: [
        {
          value: "1",
          label: "删除",
        },
        {
          value: "2",
          label: "不删除",
        },
      ],
      // 表格数据
      tableData: [],
      // excel弹出显示
      excelDialogVisible: false,
      // 淘宝弹出显示
      tbDialogVisible: false,
      // 停止框弹出显示
      stopDialogVisible: false,
      // 加载中
      loading: false,
      page: 1,
      pagesize: 20,
      total: undefined,
      // 全部專題列表
      topicList: [],
      // 显示停用启用弹窗
      showStopDialog: false,
      stopDialogData: {
        title: "",
        status: 2,
        is_delete: 1,
        scope: null,
      },
      stopDialogRule: [
        {
          required: true,
          message: "请选择",
        },
      ],
    };
  },
  created() {
    this.getListData();
  },
  activated() {
    if (this.$route.query.type) {
      this[this.$route.query.type] = true;
    }
  },
  mounted() {},
  methods: {
    get_zhuanti_list() {
      api.getTopicByKeywordList(
        {
          keyword: "",
          pagesize: 100,
        },
        (res) => {
          this.topicList = (res.data && res.data.list) || [];
        },
        (err) => {
          this[key].loading = false;
        }
      );
    },
    show_table_row_pop(e) {
      setTimeout(() => {
        this.tableData[e.$index].visible = true;
      }, 0);
    },
    stop_table_row(e) {
      this.stopRuleForm = {
        id: e.row.id,
        name: e.row.task_name || "",
        is_delete: undefined,
        okSubmit: true,
        errMessage: "",
        index: e.$index,
      };
      this.openDialog("stopDialogVisible");
    },
    editGoodsImportTaskDetail(e) {
      api.getGoodsImportTaskDetail(
        {
          id: e.row.id,
        },
        (res) => {
          const data = res.data;
          if (data.type == 1) {
            this.tbRuleForm = {
              id: data.id,
              task_name: data.task_name,
              zhuanTi: data.item_topic_id,
              task_mark: data.task_mark,
              update_frequency: parseInt(data.update_frequency),
              options: [],
              loading: false,
              // 错误提示消息
              errMessage: "",
              // 专题输入框的值
              zt_value: "",
              okSubmit: true,
            };
            this.openDialog("tbDialogVisible");
          } else if (data.type == 2) {
            this.excelRuleForm = {
              id: data.id,
              task_name: data.task_name,
              zhuanTi: data.item_topic_id,
              task_mark: data.task_mark,
              options: [],
              loading: false,
              // 错误提示消息
              errMessage: "",
              // 专题输入框的值
              zt_value: "",
              okSubmit: true,
            };

            this.openDialog("excelDialogVisible");
          } else if (data.type == 3) {
            this.defData = {
              id: data.id,
              topic_id: data.item_topic_id || [],
              importType: 1,
              taskName: data.task_name || "",
              udateNum: data.update_frequency || "",
            };
            this.showSaveTopicDialog = true;
          }
        }
      );
    },
    // 上传excel成功
    upload_success(e) {
      this.excelRuleForm.task_mark = e.url;
    },
    // 上传excel失败
    upload_err(e) {
      console.log(e);
    },
    // 专题输入框 穿key 传值
    remotetbRuleFormFormMethod(e) {
      this.ztInput("tbRuleForm", { data: e });
    },
    remoteExcelRuleFormMethod(e) {
      this.ztInput("excelRuleForm", { data: e });
    },
    ztInput(key, e) {
      let keyword = e.data;
      this[key].zt_value = e.data;
      this[key].loading = true;
      api.getTopicByKeywordList(
        {
          keyword,
          pagesize: 100,
        },
        (res) => {
          this[key].loading = false;
          this[key].options = (res.data && res.data.list) || [];
        },
        (err) => {
          this[key].loading = false;
        }
      );
    },
    // 关闭弹窗 传key
    closeDialog(key) {
      this[key] = false;
    },
    // 打开弹窗 传key
    openDialog(key) {
      this[key] = true;
      this.get_zhuanti_list();
    },
    // 专题输入下拉选择框的显示隐藏改变事件
    selectVisibleChange(key, e) {
      if (!e) {
        this[key].zt_value = "";
      }
    },
    clearDialogOldData(key) {
      if (key === "excelRuleForm") {
        this.$refs.elmUpLoad.clearUrl();
        this[key] = {
          task_name: "",
          zhuanTi: [],
          task_mark: "",
          options: [],
          loading: false,
          // 错误提示消息
          errMessage: "",
          // 专题输入框的值
          zt_value: "",
          okSubmit: true,
        };
      } else if (key === "tbRuleForm") {
        this[key] = {
          task_name: "",
          zhuanTi: [],
          task_mark: "",
          update_frequency: undefined,
          options: [],
          loading: false,
          // 错误提示消息
          errMessage: "",
          // 专题输入框的值
          zt_value: "",
          okSubmit: true,
        };
      } else if (key === "stopRuleForm") {
        this.stopRuleForm = {
          name: "",
          is_delete: undefined,
          okSubmit: true,
          errMessage: "",
          id: "",
        };
      }
    },
    //提交  key属于那个formData
    onSubmit(key) {
      if (!this[key].okSubmit) {
        return;
      }
      this[key].okSubmit = false;
      if (key === "excelRuleForm") {
        let { task_name, zhuanTi, task_mark, id } = this[key];
        let item_topic_id = zhuanTi;
        let param = {
          id: id,
          task_name,
          task_mark,
          item_topic_id,
        };
        const apiName = !!id ? "updateExcelData" : "addExcelData";
        api[apiName](
          param,
          (res) => {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.excelDialogVisible = false;
            this.getListData();
          },
          (err) => {
            this[key].errMessage = err.msg ? err.msg : "未知错误";
            this[key].okSubmit = true;
            setTimeout(() => {
              this[key].errMessage = "";
            }, 2000);
          }
        );
      } else if (key === "tbRuleForm") {
        let { task_name, zhuanTi, task_mark, update_frequency, id } = this[key];
        let item_topic_id = zhuanTi;
        let param = {
          id: id,
          task_name,
          task_mark,
          update_frequency,
          item_topic_id,
        };
        const apiName = !!id ? "updateTbMaterial" : "addTbMaterial";

        api[apiName](
          param,
          (res) => {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.tbDialogVisible = false;
            this.getListData();
          },
          (err) => {
            this[key].errMessage = err.msg ? err.msg : "未知错误";
            this[key].okSubmit = true;
            setTimeout(() => {
              this[key].errMessage = "";
            }, 2000);
          }
        );
      } else if (key === "stopRuleForm") {
        if (this.stopRuleForm.is_delete === undefined) {
          this.stopRuleForm.errMessage = "请选择是否从专题中删除商品";
          this[key].okSubmit = true;
          setTimeout(() => {
            this.stopRuleForm.errMessage = "";
          }, 3000);
          return;
        }
        api.updateTaskStatus(
          {
            id: this.stopRuleForm.id,
            status: 2,
            is_delete: this.stopRuleForm.is_delete,
          },
          (res) => {
            this.$message({
              message: "修改成功！",
              type: "success",
            });
            this.tableData[this.stopRuleForm.index].status = 2;
            this[key].okSubmit = true;
            this.stopDialogVisible = false;
          },
          (err) => {
            this.$message({
              message: "修改失败！",
              type: "success",
            });
            this[key].okSubmit = true;
            this.stopRuleForm.errMessage = err.msg || "修改失败！";
            setTimeout(() => {
              this.stopRuleForm.errMessage = "";
            }, 3000);
          }
        );
      }
    },
    // 获取数据列表
    getListData() {
      let param = {
        task_name: this.searchForm.search_input,
        status: this.searchForm.status,
      };
      this.loading = true;
      param.page = this.page;
      param.pagesize = this.pagesize;
      api.getCommodityList(
        param,
        (res) => {
          this.loading = false;
          if (!res.data || !res.data.list) {
            this.total = 0;
            this.tableData = [];
            return;
          }
          let list = res.data.list;
          this.total = Number(res.data.count);
          list.forEach((item, value) => {
            item.visible = false;
          });
          this.tableData = res.data.list;
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    // 搜索
    seach() {
      this.page = 1;
      this.pagesize = 20;
      this.total = undefined;
      this.getListData();
    },
    // 重置
    reset() {
      this.searchForm.search_input = "";
      this.searchForm.status = undefined;
      this.page = 1;
      this.pagesize = 20;
      this.total = undefined;
      this.getListData();
    },
    // 查看详情
    openDetail(e) {
      let id = e.id;
      console.log(id);
      if (!id) {
        this.$message({
          message: "沒有id",
          type: "error",
        });
        return;
      }
      // 变成 /user?id=2
      this.$router.push({
        path: "/commodity/import/detail",
        query: {
          id,
        },
      });
    },
    // 停止使用
    stop_use(e) {
      let item = e.row;
      api.updateTaskStatus(
        {
          id: item.id,
          status: 2,
          is_delete: this.stopDialogData.is_delete,
        },
        (res) => {
          this.tableData[e.$index].status = 2;
          this.tableData[e.$index].visible = false;
          this.showStopDialog = false;
          this.$message({
            message: "修改成功！",
            type: "success",
          });
        },
        (err) => {
          this.$message({
            message: "修改失败！",
            type: "success",
          });
          this.tableData[e.$index].visible = false;
        }
      );
    },
    // 启用
    start_use(e) {
      let item = e.row;
      api.updateTaskStatus(
        {
          id: item.id,
          status: 1,
          is_delete: this.stopDialogData.is_delete,
        },
        (res) => {
          this.tableData[e.$index].status = 1;
          this.tableData[e.$index].visible = false;
          this.showStopDialog = false;
          this.$message({
            message: "修改成功！",
            type: "success",
          });
        },
        (err) => {
          this.$message({
            message: "修改失败！",
            type: "success",
          });
          this.tableData[e.$index].visible = false;
        }
      );
    },

    // 根据状态码显示对应的内容
    getStatusToDetail(status) {
      if (status === undefined) return "";
      if (status == 1) {
        return "启用";
      } else if (status == 2) {
        return "停用";
      } else {
        return "其他";
      }
    },
    // 根据时间戳计算当前时间
    getTime(time) {
      time = Number(time) * 1000;
      let ntime = new Date(time);
      let year = ntime.getFullYear();
      let yue = ntime.getMonth() + 1;
      yue = yue < 10 ? "0" + yue : yue;

      let ri = ntime.getDate();
      ri = ri < 10 ? "0" + ri : ri;

      let hour = ntime.getHours();
      hour = hour < 10 ? "0" + hour : hour;

      let fen = ntime.getMinutes();
      fen = fen < 10 ? "0" + fen : fen;

      let miao = ntime.getSeconds();
      miao = miao < 10 ? "0" + miao : miao;

      return `${year}-${yue}-${ri} ${hour}:${fen}:${miao}`;
    },
    handleSizeChange(e) {
      this.pagesize = e;
      this.getListData();
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getListData();
    },
  },
};
</script>
<style lang="scss">
.commodity {
  padding: 0 20px;
  text-align: left;
}

.go_down_model {
  display: inline-block;
  margin-bottom: 10px;
}

.input_down_tishi {
  position: absolute;
  bottom: 10px;
  transform: translate(0, 100%);
  font-size: 12px;
  color: #999;
}

.tb_pinlv_tishi {
  position: absolute;
  top: 50%;
  transform: translate(100%, -50%);
  right: -5px;
  font-size: 12px;
}
</style>